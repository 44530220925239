<template>
  <b-card>
    <transition name="overlay-fade"><div class="overlay" v-if="showOverlay"></div></transition>
    <div class="form-company-info-class" v-if="!loading.companyInfo">
      <div>
        <form-render
          :key="keyFormRenderCompanyInfo"
          ref="formRenderCompanyInfo"
          :fields="fields"
          :form="form"
          class="col-12"
          @send="saveCompanyInfo"
        >
          <template #generateApiKey>
            <b-button
              variant="link"
              class="btn_add_authority_contacts"
              @click="confirmChangeApikey"
              :disabled="loading.getApiKey"
            >
              <span class="px-2 d-flex"
                ><b-spinner small v-if="loading.getApiKey" class="mr-1" /> Generar nueva API key</span
              >
            </b-button>
          </template>
          <template #add_authority_contacts>
            <b-button
              variant="outline-primary"
              @click="addAuthorityContacts(true)"
              class="btn_add_authority_contacts"
              v-b-tooltip.hover
              title="Debe llenar los campos: Nombre, apellido y correo"
            >
              <feather-icon icon="PlusIcon" size="16" />
            </b-button>
          </template>
          <template v-for="currentSlot in delete_authority_contacts" #[currentSlot]="">
            <b-button
              :key="currentSlot"
              variant="outline-warning"
              @click="() => deleteAuthorityContacts(currentSlot)"
              class="btn_add_authority_contacts"
            >
              <feather-icon icon="TrashIcon" size="16" />
            </b-button>
          </template>
          <template #goToRule>
            <label class="label-form">Regla</label>
            <div class="d-flex align-items-center">
              <span v-if="infoRuleCompany"><strong>{{ infoRuleCompany.name }}</strong></span>
              <span v-else><strong>No tiene regla asignada</strong></span>
              <b-button
                v-if="showRedirectRule"
                variant="link"
                class="btn_add_authority_contacts"
                @click="goTo()"
                target="_blank"
              >
                <span class="px-2 d-flex">Ir a la regla asignada</span>
              </b-button>
            </div>
          </template>
        </form-render>
        <div v-if="!fields.length" class="table-render-skeleton">
          <b-skeleton-table :rows="20" :columns="1" :table-props="{}"/>
        </div>
        <b-button variant="warning" class="mt-2 text-right btn-right" :disabled="loading.getCompany" @click="ok">
          <span v-if="!loading.getCompany" class="mb-1">Guardar cambios</span>
          <span class="px-2" v-if="loading.getCompany"><b-spinner small /> </span>
        </b-button>
      </div>
      <div class="">
        <b-col class="px-1 pt-2 col">
          <div class="file-drop-area text-center">
            <div class="container_company_info">
              <img class="company_logo" :src="form.logo_url_v2 || IconLogoDefault" alt="Logo de la empresa" />
            </div>
            <p class="mb-1 pt-1">Imagen (PNG) Tamaño sugerido 300px por 300px</p>
            <p class="mb-1">
              <input
                v-if="!loading.logo"
                type="file"
                @change="changeCompanyLogo"
                name="file-input-coverage"
                id="file-input-coverage"
                class="file-input invisible d-none"
                accept=".png, jpg"
              />
              <label for="file-input-coverage" class="text-center cursor-pointer m-0" style="color:#48BD5B;">
                <feather-icon v-if="!loading.logo" icon="UploadCloudIcon" size="18" class="mr-50" />
                <b-spinner small v-if="loading.logo" class="mr-1" />
                Cargar logotipo
              </label>
            </p>
          </div>
        </b-col>
        <div style="border-bottom: 1px solid #eee;;" variant="primary" class="pt-1"></div>
        <h3 class="pt-2">Historial de activacion</h3>
        <div class="ml-2 mr-2">
          <table-render :key="keyTableRender" :rows="rows" :schema="schema" tableClass="" />
        </div>
      </div>
      <div v-if="isAdmin">
        <feather-icon @click="disabledFields" icon="EditIcon" class="icon_disabled" />
      </div>
    </div>
    <div v-if="loading.companyInfo" class="table-render-skeleton">
      <b-skeleton-table :rows="20" :columns="1"  :table-props="{}"/>
    </div>
  </b-card>
</template>
<script>
import { environment } from '@/environments/environment'
import { mapGetters } from 'vuex'
import defaultLogo from '@/assets/images/icons/company_default_grey.png'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import BaseServices from '@/store/services/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'company-information',
  components: {FeatherIcon },
  data () {
    return {
      baseService: new BaseServices(this),
      company_id: this.$route.params?.id,
      keyTableRender: 0,
      keyFormRenderCompanyInfo: 0,
      form: {},
      fields: [],
      inactiveReasonOptions: [],
      activeReasonOptions: [],
      companyLogo: null,
      rows: [],
      schema: [],
      optionsNeedPurchaseOrder : [{ id: 1, text: 'Si' }, { id: 2, text: 'No' }],
      optionsDirectBilling: [{id: '1', text: 'Si'}],
      optionsActivated: [{id: '1', text: 'Activo'}, {id:'2', text: 'Inactivo'}],
      optionsMarketPlaces: [{id: '1', text: 'Si'}],
      optionsShowInvoices: [{id: '1', text: 'Si'}],
      optionsShowCyberForm: [{id: '1', text: 'Si'}],
      IconLogoDefault: defaultLogo,
      isDisabled: false,
      isAdmin: false,
      isEcommerce: false,
      isMarketplace: false,
      isSellerCencosudProd: false,
      loading: {
        logo: false,
        getCompany: false,
        companyInfo: true,
        getApiKey: false
      },
      indexAuthorityContacts: 0,
      delete_authority_contacts: [],
      showOverlay: false,
      existData: false,
      showRedirectRule: false,
      infoRuleCompany: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInfo: 'getCompanyInformation',
      apiKey: 'getApiKey',
      editCompany: 'putEditCompany',
      changeLogo: 'changeLogo',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    mySession () {
      if (this.mySession?.id && this.companyInfo.data) {
        this.verifySession()
      }
    },
    companyInfo() {
      if (this.mySession?.id && this.companyInfo.data) {
        this.verifySession()
      }
    },
    changeLogo() {
      this.setCompanyInfo()
    },
    generalLoading: {
      handler () {
        this.loading.companyInfo = !!this.generalLoading.getCompanyInformation
      }
    }
  },
  mounted () {
    this.getCompanyInfo()
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', useLabel: true, disabled: true, name: 'id', label: 'Id', containerClass: 'container-info container-info col-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldInput', useLabel: true, disabled: false, name: 'name1', label: 'Nombre de la empresa', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required' },
        { fieldType: 'FieldInput', useLabel: true, disabled: false, name: 'name2', label: 'Razón Social', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required' },
        { fieldType: 'FieldInput', useLabel: true, disabled: false, name: 'dni', label: 'WORD_DNI:', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required' },
        { fieldType: 'FieldInput', useLabel: true, disabled: false, name: 'business_activity', label: 'Giro', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required' },
        { fieldType: 'FieldSelect', useLabel: true, name: 'country', label: 'País', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required', useSkeleton: true, change: this.changeCountry},
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'place', disabled: false, label: 'Ubicación (Comuna, Colonia, Municipio ó Distrito)', validation: 'required', containerClass: 'container-info col-12 col-md-12 col-lg-12', dependency: 'country', clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600, persistSearch: true }},
        { fieldType: 'FieldInput', useLabel: true, name: 'full_address', label: 'Dirección (Calle, número)', containerClass: 'container-info col-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldSelect', useLabel: true, disabled: false, name: 'executive_id', label: 'Ejecutivo comercial', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required' },
        { name: 'authority_contacts', label: 'Contacto tomador de decisiones', useSlot: true, containerClass: 'container-info col-12' },
        { fieldType: 'FieldInput', useLabel: true, name: 'authority_contacts_full_name_0', placeholder: 'Nombre y Apellido', containerClass: 'container-info col-4 col-md-4 col-lg-4 border_authority_contacts', validation: '' },
        { fieldType: 'FieldSelect', useLabel: true, useSkeleton: true, name: 'authority_contacts_role_id_0', placeholder: 'Elige un Cargo', containerClass: 'container-info col-4 col-md-4 col-lg-4 select_border_authority_contacts'},
        { name: 'add_authority_contacts', useSlot: true, containerClass: 'container-info col-2 add_authority_contacts' },
        { name: ' ', useSlot: true, containerClass: 'container-info col-12' },
        { fieldType: 'FieldInput', useLabel: true, name: 'authority_contacts_phone_0', placeholder: 'Teléfono', containerClass: 'container-info col-6 col-md-6 col-lg-6 border_authority_contacts' },
        { fieldType: 'FieldInput', useLabel: true, name: 'authority_contacts_email_0', placeholder: 'Correo', containerClass: 'container-info col-6 col-md-6 col-lg-6 border_authority_contacts', msgError: '', change: this.changeEmail, validation: '' },
        { fieldType: 'FieldInput', useLabel: true, name: 'webpage', label: 'Sitio web', containerClass: 'container-info col-12 col-md-12 col-lg-12' },
        { name: 'space1', useSlot: true, containerClass: 'container-info col-12' },
        { fieldType: 'FieldInput', useLabel: true, name: 'contact_name', label: 'Contacto Facturación', containerClass: 'container-info col-4 col-md-4 col-lg-4', disabled: true },
        { fieldType: 'FieldInput', useLabel: true, name: 'contact_email', label: 'Correo', containerClass: 'container-info col-4 col-md-4 col-lg-4', msgError: '', change: this.changeEmail, disabled: true },
        { fieldType: 'FieldInput', useLabel: true, name: 'contact_phone', label: 'Teléfono', containerClass: 'container-info col-4 col-md-4 col-lg-4', disabled: true },
        { fieldType: 'FieldRadio', useLabel: true, disabled: false, name: 'need_purchase_order', label: '¿Necesita orden de compra?', containerClass: 'container-info col-12 col-md-12 col-lg-12', align: 'h', options:this.optionsNeedPurchaseOrder, change: this.changeNeedPurchaseOrder },
        { fieldType: 'FieldTextarea', useLabel: true, name: 'observation', label: 'Nota sobre facturación (uso interno)', containerClass: 'container-info col-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'direct_billing', label:'Facturación Directa', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.optionsDirectBilling, align: 'v' },
        { fieldType: 'FieldInput', useLabel: true, name: 'credit_days', label: 'Días de crédito', containerClass: 'container-info col-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldInput', useLabel: true, name: 'code', label: 'Código', containerClass: 'container-info col-12 col-md-12 col-lg-12', validation: 'required' },
        { name: 'space1', useSlot: true, containerClass: 'container-info col-12' },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'marketplace', label:'Marketplace', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.optionsMarketPlaces, align: 'h' },
        { fieldType: 'FieldSelect', useLabel: true, name: 'pudo_groups', multiple: true, label: 'Grupo pudos', containerClass: 'container-info col-12 col-md-12 col-lg-12', useSkeleton: true },
        { name: 'pudosTags', useSlot: true, containerClass: 'col-12' },
        { fieldType: 'FieldInput', useLabel: true, disabled: false, name: 'api_key', label: 'API key', containerClass: 'container-info col-8 col-md-8 col-lg-8', validation: 'required' },
        { name: 'generateApiKey', useSlot: true, containerClass: 'container-info col-4 add_authority_contacts' },
        //{ fieldType: 'FieldSelect', useLabel: true, name: 'rule', label: 'Regla', containerClass: 'container-info col-8 col-md-8 col-lg-8', useSkeleton: true, change: this.changeRule},
        { name: 'goToRule', useSlot: true, containerClass: 'container-info col-12' },
        { fieldType: 'FieldTextarea', useLabel: true, name: 'customization', label: 'Configuraciones personalizadas', containerClass: 'container-info col-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldTextarea', useLabel: true, name: 'default_setting', label: 'Configuraciones por default', containerClass: 'container-info col-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'show_invoices', label:'Mostrar facturas', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.optionsShowInvoices, align: 'h' },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'show_cyber_form', label:'Mostrar formulario cyber', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.optionsShowCyberForm, align: 'h' }
      ]
      this.schema = [
        {label: 'Estado', sortable: true, key: 'status'},
        {label: 'Descripcion', sortable: true, key: 'description'},
        {label: 'Usuario', sortable: true, key: 'user'},
        {label: 'Fecha', sortable: true, key: 'created_at'}
      ]
      this.verifyData()
    },
    verifySession() {
      if (this.form?.id && this.mySession?.id) {
        this.isAdmin = this.$session.get('cas_user').role === 'admin' || this.$session.get('cas_user').role === 'superadmin'
        this.isEcommerce = this.$session.get('cas_user').role === 'ecommerce' || this.$session.get('cas_user').role === 'seller'
        this.isMarketplace =  this.$session.get('cas_user').role === 'marketplace'
        this.isSellerCencosudProd = ['seller'].includes(this.$session.get('cas_user').role) && environment.platformUrl === 'https://platform.enviame.io' && [1162].includes(this.form?.dubai_organization_id)
        if (this.mySession?.id) {
          const indexExecutive_id = this.fields.findIndex((el) => el.name === 'executive_id')
          if (this.isAdmin) {
            const indexActivated = this.fields.findIndex((el) => el.name === 'activated')
            const indexInitialCollectionExecutive = this.fields.findIndex((el) => el.name === 'collection_executives_id')
            const indexOperationalEscortId = this.fields.findIndex((el) => el.name === 'operational_escort_id')
            if (indexInitialCollectionExecutive === -1) this.fields.splice(indexExecutive_id + 1, 0, { fieldType: 'FieldSelect', useLabel: true, disabled: false, name: 'collection_executives_id', label: 'Ejecutivo de cobranza', containerClass: 'container-info col-12 col-md-12 col-lg-12', useSkeleton: true })
            const indexCode = this.fields.findIndex((el) => el.name === 'code')
            if (indexActivated === -1) this.fields.splice(indexCode + 1, 0, { fieldType: 'FieldRadio', useLabel: true, name: 'activated', label: 'Estado', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.optionsActivated, align: 'h', change: this.changeActivated, disabled: false })
            if (indexOperationalEscortId === -1) this.fields.splice(indexExecutive_id + 2, 0, { fieldType: 'FieldSelect', useLabel: true, disabled: false, name: 'operational_escort_id', label: 'Escoltas operacionales', containerClass: 'container-info col-12 col-md-12 col-lg-12', useSkeleton: true })
            this.setGrupoPudos()
            this.setoptions(this.companyInfo.data.operational_escorts, 'operational_escort_id')
          } else if (this.isMarketplace) {
            const indexActivated = this.fields.findIndex((el) => el.name === 'activated')
            const indexCode = this.fields.findIndex((el) => el.name === 'code')
            if (indexActivated === -1) this.fields.splice(indexCode + 1, 0, { fieldType: 'FieldRadio', useLabel: true, name: 'activated', label: 'Estado', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.optionsActivated, align: 'h', change: this.changeActivated, disabled: false })
          }
          // desabilitat o eliminar los fields si no es admin
          if (!this.isAdmin) {
            const indexAuthorityContactsFullName = this.fields.findIndex((el) => el.name === 'authority_contacts_full_name_0')
            const indexAuthorityContactsEmail = this.fields.findIndex((el) => el.name === 'authority_contacts_email_0')
            if (indexAuthorityContactsFullName !== -1) this.fields[indexAuthorityContactsFullName].validation = 'required'
            if (indexAuthorityContactsEmail !== -1) this.fields[indexAuthorityContactsEmail].validation = 'required'
            const disabledFields = ['name2', 'dni', 'executive_id', 'operational_escort_id']
            this.disabledSomeFields(disabledFields, true)
            // Campo no obligatorio para usuarios no admin
            const indexExecutiveId = this.fields.findIndex((el) => el.name === 'executive_id')
            this.fields[indexExecutiveId].validation = ''
            
            const deleteFields = ['need_purchase_order', 'observation', 'direct_billing', 'credit_days', 'marketplace', 'pudo_groups', 'customization', 'default_setting', 'show_invoices', 'show_cyber_form']
            deleteFields.forEach((el) => {
              const index = this.fields.findIndex((field) => field.name === el)
              this.fields.splice(index, 1)
            })
          }
          if (!!this.isSellerCencosudProd) {
            const deleteFieldsForCencosud = ['generateApiKey', 'api_key']
            deleteFieldsForCencosud.forEach((el) => {
              const index = this.fields.findIndex((field) => field.name === el)
              this.fields.splice(index, 1)
            })
          }
          if (this.isEcommerce) {
            const indexCode = this.fields.findIndex((el) => el.name === 'code')
            if (indexCode !== -1) this.fields[indexCode].disabled = true
          }
          this.changeActivated('activated', this.companyInfo.data.company.activated === 1 ? this.optionsActivated[0] : this.optionsActivated[1])
          this.setFields()
          this.setoptions(this.companyInfo.data.executives, 'executive_id')
          this.setoptions(this.companyInfo.data.collection_executives, 'collection_executives_id')
          this.setoptions(this.companyInfo.data.operational_escorts, 'operational_escort_id')
          this.setAutorityContactsRoles()
          this.setRules()
        }
      } else {
        setTimeout(() => {
          this.verifySession()
        }, 200)
      }
    },
    verifyData() {
      if (this.companyInfo.data) {
        this.existData = true
        return this.setCompanyInfo()
      } else {
        setTimeout(() => {
          this.verifyData()
        }, 200)
      }
    },
    getCompanyInfo() {
      this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: {company_id: this.company_id} })
    },
    confirmChangeApikey() {
      this.$confirm(this.$t('¿Está seguro que desea generar una nueva apikey?'), () => this.getApiKey())
    },
    getApiKey() {
      const dataToastNotification = {}
      const params = {company_id: this.form.id}
      const queryParams =  {}
      this.loading.getApiKey = true
      this.baseService.callService('getApiKey', queryParams, params)
        .then(resp => {
          this.keyFormRenderCompanyInfo++
          dataToastNotification.title = 'Excelente'
          dataToastNotification.message = 'La apiKey se ha actualizado correctamente'
          dataToastNotification.variant = 'success'
          dataToastNotification.icon = 'CheckIcon'
          this.setApiKey(resp.data.token)
          this.showToastNotification(dataToastNotification)
        })
        .catch(err => {
          dataToastNotification.title = 'Oops'
          dataToastNotification.message = `La apiKey no se ha actualizado. Error: ${err.message}`
          dataToastNotification.variant = 'danger'
          dataToastNotification.icon = 'WarningIcon'
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.loading.getApiKey = false
        })
    },
    getContactEmails(emails) {
      if (Array.isArray(emails)) {
        return emails.join(', ') // Si es un array, simplemente lo unimos con comas y espacio
      } else {
        return emails // Si ya es una cadena, la devolvemos tal cual
      }
    },
    setStringFields (data) {
      return !!data && typeof data === 'object' ? JSON.stringify(data) : data
    },
    setCompanyInfo() {
      this.form = { ...this.companyInfo.data.company }
      const default_setting = this.form.default_setting ?? ''
      this.form.contact_name = this.companyInfo.data.company.billing_info?.contact_name
      this.form.contact_email = this.getContactEmails(this.companyInfo.data.company.billing_info?.contact_email)
      this.form.contact_phone = this.companyInfo.data.company.billing_info?.contact_phone
      this.form.observation = this.companyInfo.data.company.billing_info?.observation
      this.form.need_purchase_order = this.companyInfo.data.company.need_purchase_order === 1 ? this.optionsNeedPurchaseOrder[0] : this.optionsNeedPurchaseOrder[1]
      this.form.direct_billing = this.companyInfo.data.company.direct_billing === 1 ? this.optionsDirectBilling[0] : null
      this.form.activated = this.companyInfo.data.company.activated === 1 ? this.optionsActivated[0] : this.optionsActivated[1]
      this.form.marketplace = this.companyInfo.data.company.marketplace ? this.optionsMarketPlaces[0] : null
      this.form.customization = this.form.customization ? JSON.stringify(this.form.customization) : ''
      this.form.default_setting = this.setStringFields(default_setting)
      this.form.show_invoices = this.companyInfo.data.company.show_invoices === 1 ? this.optionsShowInvoices[0] : null
      this.form.show_cyber_form = this.companyInfo.data.company.show_cyber_form === 1 ? this.optionsShowCyberForm[0] : null
      if (this.companyInfo.data.company.authority_contacts?.length) {
        this.companyInfo.data.company.authority_contacts.forEach((el, index) => {
          this.form[`authority_contacts_full_name_${index}`] = el.full_name
          this.form[`authority_contacts_email_${index}`] = el.email
          this.form[`authority_contacts_phone_${index}`] = el.phone
          this.form[`authority_contacts_role_id_${index}`] = el.role_id
          this.indexAuthorityContacts++
          if (index > 0) {
            this.addAuthorityContacts(false)
          }
        })
      }
      this.form.full_address = this.companyInfo.data.company.address?.full_address
      this.rows = this.companyInfo.data.company_history
      this.setCountries()
      if (this.fields.length) this.setDniLabel(this.form.country?.code)
      this.keyFormRenderCompanyInfo++
    },
    setFields() {
      const indexExecutive_id = this.fields.findIndex((el) => el.name === 'executive_id')
      if (((this.isMarketplace || this.isEcommerce) && !this.companyInfo.data?.belong_to_marketplace) && this.form.collection_executives_id) {
        const indexInitialCollectionExecutive = this.fields.findIndex((el) => el.name === 'collection_executives_id')
        const indexCode = this.fields.findIndex((el) => el.name === 'code')
        if (indexInitialCollectionExecutive === -1) this.fields.splice(indexExecutive_id + 1, 0, { fieldType: 'FieldSelect', useLabel: true, disabled: false, name: 'collection_executives_id', label: 'Ejecutivo de cobranza', containerClass: 'container-info col-12 col-md-12 col-lg-12', useSkeleton: true })
        this.fields[indexCode].validation = ''
        this.disabledSomeFields(['collection_executives_id'], true)
      }
      if (((this.isEcommerce || this.isMarketplace) && this.companyInfo.data?.allow_view_escort) && this.form.operational_escort_id) {
        const indexOperationalEscortId = this.fields.findIndex((el) => el.name === 'operational_escort_id')
        const indexInitialCollectionExecutive = this.fields.findIndex((el) => el.name === 'collection_executives_id')
        const position = indexInitialCollectionExecutive !== -1 ? indexInitialCollectionExecutive : indexExecutive_id
        if (indexOperationalEscortId === -1) this.fields.splice(position + 1, 0, { fieldType: 'FieldSelect', useLabel: true, disabled: true, name: 'operational_escort_id', label: 'Escoltas operacionales', containerClass: 'container-info col-12 col-md-12 col-lg-12', useSkeleton: true })
      }
    },
    setCountries() {
      const index = this.fields.findIndex((el) => el.name === 'country')
      const indexCountry = this.companyInfo.data.countries.findIndex((el) => el.id === this.form.country_id)
      if (index !== -1) this.fields[index].options = this.companyInfo.data.countries.map(el => ({...el, text: el?.name}))
      if (indexCountry !== -1 && this.companyInfo.data.countries?.length) {
        this.companyInfo.data.countries[indexCountry].text = this.companyInfo.data.countries[indexCountry].name
        this.form.country = this.companyInfo.data.countries[indexCountry]
      }
      this.setSkeletonField('country', false)
      if (this.companyInfo.data.company.address?.place?.name && this.companyInfo.data.company.address?.place?.id) {
        this.setPlaces(this.companyInfo.data.company.address.place?.name, this.companyInfo.data.company.address?.place?.id)
      }
    },
    setoptions(array, field) {
      const options = array.filter(el => el.country_id === this.form.country_id)
      options.forEach(el => el.text = `${el?.first_name} ${el?.last_name} - ${el?.email}`)
      if (this.companyInfo.data.company[field]) this.form[field] = array.find((el) => el.id === this.companyInfo.data.company[field])
      const index = this.fields.findIndex((el) => el.name === field)
      if (index !== -1) this.fields[index].options = options
      this.setSkeletonField(field, false)
    },
    setAutorityContactsRoles() {
      const options = this.companyInfo.data.authority_contact_roles.map(el => ({...el, text: el?.name}))
      for (let i = 0; i <= this.indexAuthorityContacts; i++) {
        if (this.form[`authority_contacts_role_id_${i}`]) this.form[`authority_contacts_role_id_${i}`] = options.find((el) => el.id === this.form[`authority_contacts_role_id_${i}`])
        const index = this.fields.findIndex((el) => el.name === `authority_contacts_role_id_${i}`)
        if (index !== -1) this.fields[index].options = options
        this.setSkeletonField(`authority_contacts_role_id_${i}`, false)
      }
    },
    setRules() {
      const queryParams = {}
      const params = {company_id: this.company_id}
      this.baseService.callService('getCompanyRules', queryParams, params)
        .then(resp => {
          this.showRedirectRule = !!resp.data &&  !!resp?.data?.self_link
          this.infoRuleCompany = resp?.data || null
        })
        .catch(err => {
          this.showRedirectRule = false
          this.$alert(this.$t('msg-problema-cargar', {code: err}))
        })
    },
    setInactiveReasonId() {
      if (this.form.inactive_reason_id) this.form.inactive_reason = this.companyInfo.data.inactive_reasons.find((el) => el.id === parseInt(this.form.inactive_reason_id))
      const index = this.fields.findIndex((el) => el.name === 'inactive_reason')
      if (index !== -1) this.fields[index].options = this.companyInfo.data.inactive_reasons
      this.setSkeletonField('inactive_reason', false)
    },
    setActiveReasonId() {
      if (this.form.active_reason_id) this.form.active_reason = this.companyInfo.data.active_reasons.find((el) => el.id === parseInt(this.form.active_reason_id))
      const index = this.fields.findIndex((el) => el.name === 'active_reason')
      if (index !== -1) this.fields[index].options = this.companyInfo.data.active_reasons
      this.setSkeletonField('active_reason', false)
    },
    setApiKey(apikey) {
      this.setSkeletonField('api_key', true)
      this.form.api_key = apikey
      this.keyFormRenderCompanyInfo++
      setTimeout(() => {
        this.setSkeletonField('api_key', false)
      }, 100)
    },
    setDniLabel(countryCode) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const indexDNI = this.fields.findIndex(el => el.name === 'dni')
      this.fields[indexDNI].label = texts?.WORD_DNI
      this.fields[indexDNI].placeholder = texts?.FORMAT_DNI
      this.fields[indexDNI].validation = `required|taxIdValidator:${countryCode.toLowerCase().es}`
      this.fields[indexDNI].useLabel = false
      this.fields[indexDNI].useSkeleton = false
    },
    setGrupoPudos() {
      const index = this.fields.findIndex((el) => el.name === 'pudo_groups')
      if (index !== -1) this.fields[index].options = this.companyInfo.data.pudo_groups
      this.setSkeletonField('pudo_groups', false)
    },
    setSkeletonField(name, bool) {
      const index = this.fields.findIndex((el) => el.name === name)
      if (index !== -1) {
        this.fields[index].useSlot = bool
        this.fields[index].useSkeleton = bool
        this.fields[index].useLabel = bool
      }
    },
    async setPlaces(value, selection) {
      try {
        const params = { country_id: this.form.country.id }
        const queryParams = { str: value }
        const resp = await this.baseService.callService('getFullPlaces', queryParams, params)
        const response = resp.places.map(el => ({...el, text: el.full_name, valid: true}))
        if (selection !== '' && selection !== undefined && selection !== null) {
          const newPlace = response.find(el => el.id === selection)
          this.form.place = newPlace
          this.keyFormRenderCompanyInfo++
        }
        return response
      } catch (err) {
        return err
      }
    },
    changeCountry(name, value) {
      this.form.executive_id = null
      this.form.collection_executives_id = null
      this.form.operational_escort_id = null
      this.form.place = null
      this.form.country_id = value.id
      this.setoptions(this.companyInfo.data.executives, 'executive_id')
      this.setoptions(this.companyInfo.data.collection_executives, 'collection_executives_id')
      this.setDniLabel(value.code)
    },
    changeCompanyLogo() {
      this.loading.logo = true
      const file = document.getElementById('file-input-coverage').files[0]
      const data = {
        name: 'changeLogo',
        params: {company_id: this.form.id},
        data: { logo: file, type: 'profile' }
      }
      const dataToastNotification = {}
      this.showOverlay = true
      this.baseService
        .callUploadFileCustomParams(data.name, data.data, data.params)
        .then((response) => {
          dataToastNotification.title = 'Excelente'
          dataToastNotification.message = 'El logo se ha actualizado correctamente'
          dataToastNotification.variant = 'success'
          dataToastNotification.icon = 'CheckIcon'
          this.form.logo_url_v2 = response.data.company.logo_url_v2
          this.keyFormRenderCompanyInfo++
          this.showToastNotification(dataToastNotification)
        })
        .catch((err) => {
          dataToastNotification.title = 'Oops'
          dataToastNotification.message = `El logo no se ha actualizado Error: ${err.message}`
          dataToastNotification.variant = 'danger'
          dataToastNotification.icon = 'WarningIcon'
          this.showOverlay = false
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.loading.logo = false
        })
    },
    changeActivated(name, value) {
      const indexActivated = this.fields.findIndex((el) => el.name === 'activated')
      if (value.id === '1') {
        const indexActiveReasonId = this.fields.findIndex((el) => el.name === 'active_reason')
        if (indexActiveReasonId === -1 && this.isAdmin && (this.companyInfo.data.company_history.length > 1 || this.companyInfo.data.actual_status === 0)) this.fields.splice(indexActivated + 1, 0, { fieldType: 'FieldSelect', useLabel: true, name: 'active_reason', label: 'Motivo de activacion:', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.activeReasonOptions, change: this.changeActiveReasonId, disabled: false })
        const indexInactiveReasonId = this.fields.findIndex((el) => el.name === 'inactive_reason')
        if (indexInactiveReasonId !== -1) this.fields.splice(indexInactiveReasonId, 1)
        delete this.form.inactive_reason_id
        if (this.isAdmin) this.setActiveReasonId()
      } else if (value.id === '2') {
        const indexInactiveReasonId = this.fields.findIndex((el) => el.name === 'inactive_reason')
        if (indexInactiveReasonId === -1  && this.isAdmin) this.fields.splice(indexActivated + 1, 0, { fieldType: 'FieldSelect', useLabel: true, name: 'inactive_reason', label: 'Motivo por la inactividad:', containerClass: 'container-info col-12 col-md-12 col-lg-12', options: this.inactiveReasonOptions, change: this.changeInActiveReasonId, disabled: false })
        const indexActiveReasonId = this.fields.findIndex((el) => el.name === 'active_reason')
        if (indexActiveReasonId !== -1) this.fields.splice(indexActiveReasonId, 1)
        delete this.form.active_reason_id
        if (this.isAdmin) this.setInactiveReasonId()
      }
    },
    changeActiveReasonId(name, value) {
      this.form.active_reason_id = value.id
    },
    changeInActiveReasonId(name, value) {
      this.form.inactive_reason_id = value.id
    },
    changeRule(name, value) {
      if (value) this.form.rule_id = value.id
      else this.form.rule_id = null
    },
    changeEmail(name, value) {
      const index = this.fields.findIndex((el) => el.name === name)
      this.fields[index].msgError = ''
    },
    validateEmail(fields) {
      let isValid = true
      fields.forEach((field) => {
        const arrayOfEmails = this.form[field] ? this.form[field].split(',').map(el => el.trim()) : []
        const index = this.fields.findIndex((el) => el.name === field)
        if ((arrayOfEmails.length > 0 && this.m_validateEmail(arrayOfEmails)) || (this.fields[index]?.validation === 'required' && !this.form[field])) {
          this.showAlertError()
          if (index !== -1) this.fields[index].msgError = 'Ingrese un correo válido o varios separados por comas.'
          isValid = false
        } else if (index !== -1) {
          this.fields[index].msgError = ''
        }
      })
      return isValid
    },
    disabledFields() {
      const disabledFields = ['name1', 'name2', 'dni', 'business_activity', 'executive_id', 'collection_executives_id', 'operational_escort_id', 'need_purchase_order', 'activated', 'api_key', 'active_reason', 'inactive_reason']
      this.isDisabled = !this.isDisabled
      this.disabledSomeFields(disabledFields, this.isDisabled)
    },
    disabledSomeFields(array, value) {
      array.forEach((el) => {
        const index = this.fields.findIndex((field) => field.name === el)
        if (index !== -1) this.fields[index].disabled = value
      })
      this.keyFormRenderCompanyInfo++
    },
    goTo () {
      const { self_link } = this.infoRuleCompany
      window.open(`${environment.platformUrl}${self_link}`, '_blank')
    },
    addAuthorityContacts(firstCharge) {
      if (this.form[`authority_contacts_full_name_${this.indexAuthorityContacts - 1}`] && this.form[`authority_contacts_email_${this.indexAuthorityContacts - 1}`]) {
        const arrayAuthorityContacts = this.fields.filter((el) => el.name.includes('authority_contacts_email_')).map((el) => el.name)
        if (!!firstCharge) {
          this.indexAuthorityContacts++
        }
        const optionsAuthorityContacts = this.companyInfo.data.authority_contact_roles.map(el => ({...el, text: el?.name}))
        const index = this.fields.findIndex((el) => el.name === arrayAuthorityContacts[arrayAuthorityContacts.length - 1])
        this.fields.splice(index + 1, 0,
          { fieldType: 'FieldInput', useLabel: true, name: `authority_contacts_full_name_${this.indexAuthorityContacts - 1}`, placeholder: 'Nombre y Apellido', containerClass: 'container-info col-4 col-md-4 col-lg-4 border_authority_contacts', validation: 'required' },
          { fieldType: 'FieldSelect', useLabel: true, name: `authority_contacts_role_id_${this.indexAuthorityContacts - 1}`, placeholder: 'Elige un Cargo', containerClass: 'container-info col-4 col-md-4 col-lg-4 select_border_authority_contacts', options: optionsAuthorityContacts },
          { name: `delete_authority_contacts_${this.indexAuthorityContacts - 1}`, useSlot: true, containerClass: 'container-info col-2 add_authority_contacts' },
          { name: ' ', useSlot: true, containerClass: 'container-info col-12' },
          { fieldType: 'FieldInput', useLabel: true, name: `authority_contacts_phone_${this.indexAuthorityContacts - 1}`, placeholder: 'Teléfono', containerClass: 'container-info col-6 col-md-6 col-lg-6 border_authority_contacts' },
          { fieldType: 'FieldInput', useLabel: true, name: `authority_contacts_email_${this.indexAuthorityContacts - 1}`, placeholder: 'Correo:', containerClass: 'container-info col-6 col-md-6 col-lg-6 border_authority_contacts', validation: 'required', msgError: '', change: this.changeEmail }
        )
        this.delete_authority_contacts.push(`delete_authority_contacts_${this.indexAuthorityContacts - 1}`)
      }

    },
    deleteAuthorityContacts(slot) {
      this.delete_authority_contacts.filter((el) => el !== slot)
      const index = this.fields.findIndex((el) => el.name === slot)
      const number = slot.split('_')[3]
      const elemntsToDelete = [`authority_contacts_full_name_${number}`, `authority_contacts_role_id_${number}`, `delete_authority_contact_${number}`, `authority_contacts_phone_${number}`, `authority_contacts_email_${number}`]
      this.fields.splice(index - 2, 6)
      elemntsToDelete.forEach((el) => {
        delete this.form[el]
      })
      this.indexAuthorityContacts--
    },
    async ok (e) {
      e.preventDefault()
      this.$refs.formRenderCompanyInfo.checkForm()
      if (!await this.$refs.formRenderCompanyInfo.onlyCheckForm()) this.showAlertError()
    },
    showAlertError() {
      const dataToastNotification = {}
      dataToastNotification.title = 'Oops'
      dataToastNotification.message = 'No fue posible editar la empresa. No has completado todos los datos obligatorios del formulario.'
      dataToastNotification.variant = 'danger'
      dataToastNotification.icon = 'WarningIcon'
      this.showToastNotificationError(dataToastNotification)
    },
    saveCompanyInfo() {
      const arrayAuthorityContacts = this.fields.filter((el) => el.name.includes('authority_contacts_email_')).map((el) => el.name)
      const isValid = this.validateEmail(['contact_email', ...arrayAuthorityContacts])
      if (!isValid) return
      const data = {}
      const dataToastNotification = {}
      this.loading.getCompany = true
      data.activated = this.form.activated.id === '1' ? 1 : 0
      if (this.form.active_reason_id) data.active_reason_id = this.form.active_reason_id
      data.address = {
        full_address: this.form.full_address,
        place: this.form.place,
        place_id: this.form.place?.id ? this.form.place.id : null,
        place_name: this.form.place?.name,
        zip_code: this.form.zip_code || null
      }
      data.address_id = this.form.address?.id ? this.form.address.id : null
      if (this.form.api_key) data.api_key = this.form.api_key
      data.authority_contacts = []
      let index = 0
      for (let i = 0; i <= (this.indexAuthorityContacts); i++) {
        if ((this.form[`authority_contacts_email_${index}`] && this.form[`authority_contacts_full_name_${index}`]) || index === 0) {
          data.authority_contacts.push({
            email: this.form[`authority_contacts_email_${index}`] || '',
            full_name: this.form[`authority_contacts_full_name_${index}`] || '',
            id: this.form[`authority_contacts_email_${index}`] ? this.companyInfo.data.company.authority_contacts[index]?.id || '' : '',
            phone: this.form[`authority_contacts_phone_${index}`] || '',
            role_id: (this.form[`authority_contacts_role_id_${index}`] && typeof this.form[`authority_contacts_role_id_${index}`] === 'object') ? ('id' in this.form[`authority_contacts_role_id_${index}`] ? this.form[`authority_contacts_role_id_${index}`].id : '') : this.form[`authority_contacts_role_id_${index}`] || ''
          })
          if (this.companyInfo.data.company.authority_contacts[index]?.company_id) data.authority_contacts.company_id = this.companyInfo.data.company.authority_contacts[index].company_id
          if (this.companyInfo.data.company.authority_contacts[index]?.created_at) data.authority_contacts.created_at = this.companyInfo.data.company.authority_contacts[index].created_at
          if (this.companyInfo.data.company.authority_contacts[index]?.deleted_at) data.authority_contacts.deleted_at = this.companyInfo.data.company.authority_contacts[index].deleted_at
          if (this.companyInfo.data.company.authority_contacts[index]?.updated_at) data.authority_contacts.updated_at = this.companyInfo.data.company.authority_contacts[index].updated_at
        }
        index++
      }

      if (this.form.billing) data.billing = this.form.billing
      data.billing_info = {
        contact_name: this.form.contact_name,
        contact_email: this.form.contact_email,
        contact_phone: this.form.contact_phone,
        observation: this.form.observation}
      if (this.form.business_activity) data.business_activity = this.form.business_activity
      if (this.companyInfo.data.company.cairoConfig) data.cairoConfig = this.companyInfo.data.company.cairoConfig
      if (this.form.carrier_company) data.carrier_company = this.form.carrier_company
      if (this.form.code) data.code = this.form.code
      data.collection_executives_id = this.form.collection_executives_id?.id ? this.form.collection_executives_id.id : null
      if (this.companyInfo.data.company.company_marketplaces) data.company_marketplaces = this.companyInfo.data.company.company_marketplaces
      if (this.form.company_status_reason) data.company_status_reason = this.form.company_status_reason
      if (this.form.country) data.country = this.form.country
      data.country_id = this.form.country?.id ? this.form.country.id : null
      if (this.form.created_at) data.created_at = this.form.created_at
      if (this.form.credit_days) data.credit_days = this.form.credit_days
      if (this.form.customization) data.customization = JSON.parse(this.form.customization)
      if (this.form.default_setting) data.default_setting = JSON.parse(this.form.default_setting)
      if (this.form.deleted_at) data.deleted_at = this.form.deleted_at
      data.direct_billing = this.form.direct_billing ? 1 : 0
      if (this.form.dni) data.dni = this.form.dni
      if (this.form.dubai_organization_id) data.dubai_organization_id = this.form.dubai_organization_id
      if (this.form.dubai_shipper_id) data.dubai_shipper_id = this.form.dubai_shipper_id
      data.executive_id = this.form.executive_id?.id ? this.form.executive_id.id : null
      if (this.form.id) data.id = this.form.id
      if (this.companyInfo.data.company.executive_id) data.initial_executive = this.companyInfo.data.company.executive_id
      if (this.form.inactive_reason_id) data.inactive_reason_id = this.form.inactive_reason_id
      if (this.form.integrations) data.integrations = this.form.integrations
      if (this.form.logo_url_v2) data.logo_url = this.form.logo_url_v2
      if (this.form.logo_url_v2) data.logo_url_v2 = this.form.logo_url_v2
      if (this.form.logo_verify) data.logo_verify = this.form.logo_verify
      if (this.form.mail_client) data.mail_client = this.form.mail_client
      data.marketplace = !!this.form.marketplace
      if (this.form.marketplace_id) data.marketplace_id = this.form.marketplace_id
      if (this.form.max_size) data.max_size = this.form.max_size
      if (this.form.name1) data.name1 = this.form.name1
      if (this.form.name2) data.name2 = this.form.name2
      data.need_purchase_order = this.form.need_purchase_order.id === 1 ? 1 : 0
      if (this.form.notifications) data.notifications = this.form.notifications
      data.operational_escort_id = this.form.operational_escort_id?.id ? this.form.operational_escort_id.id : null
      if (this.form.payer) data.payer = this.form.payer
      if (this.form.payer_id) data.payer_id = this.form.payer_id
      if (this.form.protocol) data.protocol = this.form.protocol
      data.pudo_groups = this.form.pudo_groups.map(el => el.id)
      if (this.form.return_mail_template) data.return_mail_template = this.form.return_mail_template
      data.rule_id = this.form.rule?.id ? this.form.rule.id : null
      data.show_cyber_form = this.form.show_cyber_form ? 1 : 0
      data.show_invoices = this.form.show_invoices ? 1 : 0
      if (this.form.updated_at) data.updated_at = this.form.updated_at
      if (this.form.webpage) data.webpage = this.form.webpage
      this.showOverlay = true
      this.baseService.callService('putEditCompany', data, {company_id: this.form.id})
        .then(resp => {
          this.keyFormRenderCompanyInfo++
          dataToastNotification.title = 'Excelente'
          dataToastNotification.message = 'La empresa se ha actualizado correctamente'
          dataToastNotification.variant = 'success'
          dataToastNotification.icon = 'CheckCircleIcon'
          this.indexAuthorityContacts = 0
          this.getCompanyInfo()
          this.showToastNotification(dataToastNotification)
        })
        .catch(err => {
          dataToastNotification.title = 'Oops'
          dataToastNotification.message = `La empresa no se ha actualizado. Error: ${err.message}`
          dataToastNotification.variant = 'danger'
          dataToastNotification.icon = 'WarningIcon'
          this.showOverlay = false
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.keyFormRenderCompanyInfo++
          this.loading.getCompany = false
        })
    },
    showToastNotification(dataToastNotification) {
      const h = this.$createElement
      const vNodesTitle = h(
        'div', { class: ['d-flex'] }, [
          h('p',
            { class: ['align-self-center', 'mb-0', 'p-0'] },
            [
              h('feather-icon', { props: { icon: 'CheckCircleIcon' }})
            ]
          ),
          h('p',
            { class: ['text-left', 'mb-0', 'pl-1'] },
            [
              h('p', { class: ['mb-0', 'head-toast'] }, ` ¡${dataToastNotification.title}! `),
              h('p', { class: ['mb-0', 'body-toast'] }, `${dataToastNotification.message}`)
            ]
          ),
          h('feather-icon', { props: { icon: 'XIcon' }, class: 'button-close-toast', on: { click: this.hideOverlay } }, 'X')
        ]
      )
      this.$bvToast.toast(' ', {
        title: vNodesTitle,
        variant: 'danger',
        id: 'editComoany',
        toaster: 'b-toaster-bottom-left',
        toastClass: 'toast-custom-class',
        headerClass: 'headerToastClass',
        headerTag: 'headerToastClass',
        solid: true,
        noCloseButton: true,
        autoHideDelay: 5000
      })
      setTimeout(() => {
        this.showOverlay = false
      }, 5500)
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    },
    hideOverlay() {
      this.$bvToast.hide('editComoany')
      this.showOverlay = false
      this.loading.getCompany = false
    }
  }
}
</script>
<style lang="scss">
.company_logo {
  height: 260px;
  width: 260px;
  max-height: 260px;
  max-width: 260px;
}
.container_company_info {
  width: 260px;
  height: 260px;
  border: 2px dashed #ccc;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.icon_disabled :hover {
  cursor: pointer;
}
.add_authority_contacts {
  align-self: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 6px;

}
.btn_add_authority_contacts {
  margin-bottom: 0 !important;
}
.border_authority_contacts input{
  border: 1px solid #1ab394;
}
.select_border_authority_contacts div{
  border-color: #1ab394 !important;
}
.badge__cnt{
  margin: 5px;
  background: #044389;
  padding: 2px 5px;
}
.color_icon{
  color: #fff;
}
.btn-right {
  float: right;
}
.form-company-info-class {
  display: grid;
  grid-template-columns: 60% 39% 1%;
}
@media (max-width: 768px) {
  .form-company-info-class {
    display: block;
  }
}
</style>
